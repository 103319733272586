const formLabel = 'Label';
const placeholder = 'Optional Placeholder';
const selectOptions = '<option value="1">One</option><option value="2">Two</option><option value="3">Three</option>';

// All examples currently use markup where the input is the child of a label,
// but this is useful for testing out the styles for when they are siblings
// and verifying that there are no changes
// export const DefaultTextInput = `<form class="lux-form">
//   <label class="form-label">`+ formLabel + `</label>
//   <input type="text" class="form-input" placeholder="`+ placeholder + `">
// </form>`;

export const DefaultTextInput = `<form class="lux-form">
  <label class="form-label">`+ formLabel + `
    <input type="text" class="form-input" placeholder="`+ placeholder + `">
  </label>
</form>`;

export const DisabledTextInput = `<form class="lux-form">
  <label class="form-label">`+ formLabel + `
    <input type="text" class="form-input" disabled placeholder="`+ placeholder + `">
  </label>
</form>`;

export const InvalidTextInput = `<form class="lux-form">
  <fieldset class="form-feedback has-error">
    <label class="form-label">`+ formLabel + `
      <input type="text" class="form-input" placeholder="`+ placeholder + `">
    </label>
  </fieldset>
</form>`;

export const FWTextInput = `<form class="lux-form">
  <label class="form-label">`+ formLabel + `
    <input type="text" class="form-input form-input--fw" placeholder="`+ placeholder + `">
  </label>
</form>`;

export const IconPrefixInput = `<form class="lux-form">
  <label class="form-label">`+ formLabel + `
    <input type="text" class="form-input has-icon icon--prefix icon--calendar" placeholder="`+ placeholder + `">
  </label>
</form>`;

export const IconSuffixInput = `<form class="lux-form">
  <label class="form-label">`+ formLabel + `
    <input type="text" class="form-input has-icon icon--suffix icon--calendar" placeholder="`+ placeholder + `">
  </label>
</form>`;

export const DefaultSelect = `<form class="lux-form">
  <label class="form-label">`+ formLabel + `
    <select class="form-input">`
  + selectOptions +
  `</select>
  </label>
</form>`;

export const DisabledSelect = `<form class="lux-form">
  <label class="form-label">`+ formLabel + `
    <select class="form-input" disabled>`
      + selectOptions +
      `</select>
  </label>
</form>`;
