function getItem(name) {
  if (window.localStorage) {
    if (name) {
      return window.localStorage[name];
    }
  }

  return undefined;
}

function setItem(name, value) {
  if (window.localStorage) {
    window.localStorage.setItem(name, value);
    return true;
  }

  return false;
}

export default {
  getItem,
  setItem
};
