import React, { Component } from 'react';

export default class SwatchItem extends Component { 

  get colorShade() {
    return (this.props.shade === "default") ? this.props.id : this.props.id + '-' + this.props.shade;
  }

    render() {
        return (
          <li className="swatch-item">
              <div
                className={'swatch fill-'+this.colorShade}
                title={'.fill-'+this.colorShade}
              ></div>
              <div className="swatch-details">
                <code
                    className={'text-'+this.colorShade+' fill-ebony-clay-darkest'}
                    title={'.text-'+this.colorShade}
                  >{this.props.shade}</code>
                <code
                  className={'text-'+this.colorShade+' fill-concrete-lightest'}
                  title={'.text-'+this.colorShade}
                >{this.props.shade}</code>
              </div>
              {/* @TODO Might consider making these buttons later */}
              {/* Would make it easier to copy the class names? */}
          </li>
        )
    }
}
