import React from 'react';

export default props => 
<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  aria-hidden="true"
  className="icon icon-select"
>
  <g id="Select" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g fill="currentColor" transform="translate(2.000000, 2.000000)">
      <path d="M10,16 L6,12 L14,12 L10,16 Z M10,4 L6,8 L14,8 L10,4 Z"></path>
    </g>
  </g>
</svg>;
