import React from 'react';

export default props =>
<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 20"
  aria-hidden="true"
  className="icon icon-check"
>
  <g id="Check" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(1.000000, 1.000000)" fill="currentColor">
      <path d="M6.31475766,11.8585 L3.13295394,8.4415 C2.95568202,8.2535 2.95568202,7.9475 3.13295394,7.7575 L3.77567829,7.0745 C3.95295021,6.8865 4.24113072,6.8865 4.41840264,7.0745 L6.63657438,9.4665 L11.5810974,4.1415 C11.7583693,3.9535 12.0465498,3.9535 12.2238217,4.1415 L12.8665461,4.8255 C13.043818,5.0135 13.043818,5.3205 12.8665461,5.5075 L6.95748201,11.8585 C6.78021009,12.0465 6.49202958,12.0465 6.31475766,11.8585" id="check"></path>
    </g>
  </g>
</svg>;
