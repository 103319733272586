import React, { Component } from 'react';
import MainContent from 'components/shared/main-content';
import Changes from './../../../../../CHANGELOG.md';

export default class Changelog extends Component {
  render() {
    return (
      <MainContent title="Changelog">
        <div className="changelog-wrapper" dangerouslySetInnerHTML={{ __html: Changes }}></div>
      </MainContent>
    );
  }
}

