import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import menuItems from 'lib/main-menu';

export default class GuideNav extends Component {
  getItems(items) {
    return items.map((menuItem) => {
      return (
        <li key={menuItem.id}>
          {!menuItem.path &&
            <p className="nav-heading">{menuItem.text}</p>
          }
          {!!menuItem.path &&
            <NavLink
              to={menuItem.path}
              className=""
              activeClassName="is-active"
              exact
            >
              {menuItem.text}
            </NavLink>
          }
          {!!menuItem.items && !!menuItem.items.length &&
            <ul className="u-list-reset">
             {this.getItems(menuItem.items)}
            </ul>
          }
        </li>
      );
    });
  }

  render() {
    return (
      <nav className="guide-nav">
        <ul className="u-list-reset">
          {this.getItems(menuItems)}
        </ul>
      </nav>
    );
  }
}
