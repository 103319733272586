import React, { Component } from 'react';
import SwatchItem from './swatch-item';

export default class SwatchGroup extends Component { 
    render() {
        const swatches = [ "lightest", "lighter", "light", "default", "dark", "darker", "darkest" ]
        return (
            <section>
                <h3>{this.props.name} &mdash; <span className="u-allcaps">{this.props.hex}</span></h3>
                <ul className="swatch-list u-list-reset">
                { swatches.map((swatch, index) =>
                    <SwatchItem key={index}
                        id={this.props.id}
                        shade={swatch}
                    />
                )}
                </ul>
            </section>
        )
    }
}
