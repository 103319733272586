import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import storage from 'lib/storage';

import Header from 'components/shell/header';
import Main from 'components/shell/main';
import Nav from 'components/shell/nav';
import Auth from 'components/views/auth';

export default class LuxApp extends Component {
  constructor(...args) {
    super(...args);

    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
  }

  state = {
    isAuthenticated: false
  };

  componentWillMount() {
    // Authentication is only checked in "production" mode
    const isAuthenticated = process.env.NODE_ENV !== 'production'
        || !!storage.getItem('lux-auth');

    this.setState({ isAuthenticated });
  }

  handlePasswordSubmit(isValid) {
    storage.setItem('lux-auth', isValid ? 'true' : '');
    this.setState({ isAuthenticated: isValid });
  }

  render() {
    return (
      <Router>
        <Fragment>
          <Header />

          {!this.state.isAuthenticated &&
            <Auth onPasswordSubmit={this.handlePasswordSubmit} />
          }

          {!!this.state.isAuthenticated &&
            <div className="guide-container">
              <Nav />
              <Main />
            </div>
          }
        </Fragment>
      </Router>
    );
  }
}
