import React, { Component } from 'react';

import MainContent from 'components/shared/main-content';
import PatternGroup from 'components/shared/pattern-group';
import Pattern from 'components/shared/pattern';

export default class Spacing extends Component {

  render() {
    const spacingSizes = ["-none", "-xxs", "-xs", "-sm", "", "-lg", "-xl"];
    return (
      <MainContent name="u-spacing" title="Spacing" showCodeToggle>
        <PatternGroup title="Padding">
          <Pattern inGroup>
            <ul className="u-list-reset u-flex guide-example__padding">
              {spacingSizes.map((size, index) =>
                <li key={index} className={'box u-pointer u-padding' + size}>
                  <code>{'.u-padding' + size}</code>
                </li>
              )}
            </ul>
          </Pattern>
          <Pattern inGroup>
            <ul className="u-list-reset u-flex u-margin-top-sm guide-example__padding">
              <li className='box u-pointer u-padding-top u-padding-right-xl u-padding-bottom-xl u-padding-left-none'>
                <code>
                  .u-padding-top-xl
                  .u-padding-right-lg
                  .u-padding-bottom-sm
                  .u-padding-left-none
                </code>
              </li>
              <li className='box u-pointer u-padding-horizontal-xl'>
                <code>.u-padding-horizontal-xl</code>
              </li>
              <li className='box u-pointer u-padding-vertical-xl'>
                <code>.u-padding-vertical-xl</code>
              </li>
            </ul>
          </Pattern>
        </PatternGroup>
        <PatternGroup title="Margin">
          <Pattern inGroup>
            <ul className="u-list-reset guide-example__margin">
              {spacingSizes.map((size, index) =>
                <li key={index} className='u-pointer wrapper'>
                  <div className={'box u-margin' + size}>
                    <code>{'.u-margin' + size}</code>
                  </div>
                </li>
              )}
            </ul>
          </Pattern>
          <Pattern inGroup>
            <ul className="u-list-reset guide-example__margin">
              <li className='u-pointer wrapper--fw'>
                <div className='box u-margin-horizontal-auto'>
                  <code>.u-margin-horizontal-auto</code>
                </div>
              </li>
            </ul>
          </Pattern>
        </PatternGroup>
      </MainContent>
    );
  }
}
