import React, { Component } from 'react';

import MainContent from 'components/shared/main-content';
import Pattern from 'components/shared/pattern';

export default class ZIndex extends Component {

  render() {
    const zIndexes = ["top", "high", "mid", "low", "lower", "bottom"];
    const zShuffled = ["high", "top", "high", "top"];
    return (
      <MainContent name="u-zindex" title="Z-Index" showCodeToggle>
        <Pattern>
          <ul className="u-list-reset guide-example__zindex">
            {zIndexes.map((z, index) =>
              <li key={index} className={'box u-pointer u-z-' + z}>
                <code>{'.u-z-' + z}</code>
              </li>
            )}
          </ul>
          <ul className="u-list-reset guide-example__zindex">
            {zShuffled.map((z, index) =>
              <li key={index} className={'box u-pointer u-z-' + z}>
                <code>{'.u-z-' + z}</code>
              </li>
            )}
          </ul>
        </Pattern>
      </MainContent>
    );
  }
}
