const buttonLabel = ' Button';
const linkLabel = 'Link Styled Like  ';

export const Default = `<button class="button">Default` + buttonLabel +`</button>
<a href="#" class="button">` + linkLabel + `Default` + buttonLabel + `</a>`;
export const DefaultReact = `<Button>Default` + buttonLabel +`</Button>`;
export const DefaultDisabled = `<button class="button" disabled>Disabled` + buttonLabel +`</button>`;
export const DefaultDisabledReact = `<Button disabled>Disabled` + buttonLabel +`</Button>`;

export const Primary = `<button class="button button--primary">Primary` + buttonLabel +`</button>
<a href="#" class="button button--primary">` + linkLabel + `Primary` + buttonLabel + `</a>
<button class="button button--primary" disabled>Disabled` + buttonLabel +`</button>`;
export const PrimaryReact = `<Button primary>Primary` + buttonLabel +`</Button>
<Button primary disabled>Disabled` + buttonLabel +`</Button>`;

export const ButtonSizes = `<button class="button button--slim">Slim` + buttonLabel +`</button>
<button class="button button">Default` + buttonLabel +`</button>
<button class="button button--large">Large` + buttonLabel +`</button>`;
export const ButtonSizesReact = `<Button size="slim">Slim` + buttonLabel +`</Button>
<Button>Default` + buttonLabel +`</Button>
<Button size="large">Large` + buttonLabel +`</Button>
`;

export const ButtonGroup = `<div class="button-group">
  <button class="button">One</button>
  <button class="button">Two</button>
  <button class="button">Three</button>
</div>`;
export const ButtonGroupReact = `<ButtonGroup>
  <Button>One</Button>
  <Button>Two</Button>
  <Button>Three</Button>
</ButtonGroup>`;

export const ButtonToggle =`<button class="u-button-reset button-group button-toggle js-button-toggle" role="switch" type="button" aria-checked="false" aria-label="Update this label for your feature.">
  <span class="button">On</span>
  <span class="button">Off</span>
</button>`;
export const ButtonToggleReact = `<ButtonToggle
  ariaLabel="Update this label for your feature."
  optionOne="On"
  optionTwo="Off" />`;
