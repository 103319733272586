import React, { Component } from 'react';

import MainContent from 'components/shared/main-content';

export default class NotFound extends Component {
  render() {
    return (
      <MainContent>
        <h1>Oops, that's not a real page.</h1>
      </MainContent>
    );
  }
}
