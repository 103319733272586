import React, { Component } from 'react';

import MainContent from 'components/shared/main-content';
import Pattern from 'components/shared/pattern';

import * as Code from './code';

export default class Display extends Component {

  render() {
    return (
      <MainContent name="u-display" title="Display" showCodeToggle>
        <Pattern title="Block" code={Code.DisplayBlock}>
          <span className="u-block box">SPAN acting as block element</span>
        </Pattern>
        <Pattern title="Inline Block" code={Code.DisplayInlineBlock}>
          <div className="u-inline-block box">DIV acting as inline-block element</div>
        </Pattern>
        <Pattern title="Flex" code={Code.DisplayFlex}>
        <ul className="u-list-reset u-flex">
          <li className="box">One</li>
          <li className="box">Two</li>
          <li className="box">Three</li>
        </ul>
        </Pattern>
      </MainContent>
    );
  }
}
