import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CodeBlock from 'components/shared/code-block';

export default class Pattern extends Component {
  static propTypes = {
    id: PropTypes.string,
    inGroup: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    code: PropTypes.any,
    language: PropTypes.string,
    reactCode: PropTypes.string,
    title: PropTypes.string
  };

  static defaultProps = {
    id: null,
    inGroup: false,
    children: null,
    className: null,
    code: null,
    language: "markup",
    reactCode: null,
    title: null
  };

  constructor(...args) {
    super(...args);

    this.handleCopyClick = this.handleCopyClick.bind(this);
    this.handleCodeToggle = this.handleCodeToggle.bind(this);
  }

  state = {
    codeType: 'html'
  };

  handleCopyClick() {
    if (this.copyable) {
      this.copyable.select();
      document.execCommand('copy');
    }
  }

  handleCodeToggle(codeType) {
    this.setState({ codeType });
  }

  getCodeButtons(codeType) {

    return (
      <div className="guide-code__buttons">
        {!!this.props.reactCode &&
          <div className="button-group">
            <button
              type="button"
              onClick={event => this.handleCodeToggle('html')}
              className={"guide-code__label" + (this.state.codeType === 'html' ? " is-selected" : "")}
            >HTML</button>
            <button
              type="button"
              onClick={event => this.handleCodeToggle('react')}
              className={"guide-code__label" + (this.state.codeType === 'react' ? " is-selected" : "")}
            >React</button>
          </div>
        }
        <button
          type="button"
          onClick={this.handleCopyClick}
          className="guide-code__label guide-code__label--copy"
        >Copy</button>
      </div>
    );
  }

  setHeader() {
    var titleString = this.props.title ? this.props.title.replace(/\s+/g, '-').toLowerCase() : null;
    var idAttribute = this.props.id || titleString;

    return this.props.inGroup ? <h3 id={idAttribute}>{this.props.title}</h3> : <h2 id={idAttribute}>{this.props.title}</h2>;
  }


  render() {

    let code = this.props.code;
    let language = this.props.language;

    if (this.state.codeType === 'react') {
      code = this.props.reactCode;
    }

    return (
      <section className={this.props.className}>
        {this.setHeader()}

        {!!this.props.children &&
          <div className="guide-example">
            <p className="guide-example__label">Example</p>
            <div className="guide-example__container">
              {this.props.children}
            </div>
          </div>
        }

        {!!code &&
          <div className="guide-code">
            {this.getCodeButtons()}
            <input
              className="u-sr-only"
              value={code}
              ref={(div) => this.copyable = div}
              readOnly
            />
            <CodeBlock code={code} language={language} />
          </div>
        }
      </section>
    );
  }
}
