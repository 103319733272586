import React from 'react';
import ReactDOM from 'react-dom';

import 'lux/styles/index.scss';
import '../css/lux-guide.scss';

import App from './app';

ReactDOM.render(
  <App />,
  document.getElementById('lux-app')
);
