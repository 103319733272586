import ButtonsDescription from './buttons/description';

import ColorsDescription from './colors/description';

import FormsDescription from './forms/description';

import UDisplayDescription from './u-display/description';
import UPointerDescription from './u-pointer/description';
import UResetsDescription from './u-resets/description';
import USpacingDescription from './u-spacing/description';
import USROnlyDescription from './u-sr-only/description';
import UZIndexDescription from './u-zindex/description';

export default {
  buttons: {
    description: ButtonsDescription,
  },
  colors: {
    description: ColorsDescription,
  },
  forms: {
    description: FormsDescription,
  },
  "u-display": {
    description: UDisplayDescription,
  },
  "u-pointer": {
    description: UPointerDescription,
  },
  "u-resets": {
    description: UResetsDescription,
  },
  "u-spacing": {
    description: USpacingDescription,
  },
  "u-sr-only": {
    description: USROnlyDescription,
  },
  "u-zindex": {
    description: UZIndexDescription,
  },
};
