import CalendarIcon from './calendar';
import CheckIcon from './check';
import SelectIcon from './select';
import SpinnerIcon from './spinner';

export {
  CalendarIcon,
  CheckIcon,
  SelectIcon,
  SpinnerIcon
};
