import React, { Component } from 'react'
import PropTypes from 'prop-types';

import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/nightOwlLight';

export default class CodeBlock extends Component {
  static propTypes = {
    code: PropTypes.string.isRequired,
    language: PropTypes.string
  };

  static defaultProps = {
    language: 'markup'
  };

  render() {
    const code = this.props.code;

    return (
      <Highlight {...defaultProps} theme={theme} code={code} language={this.props.language}>
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <pre className={className} style={style}>
        {tokens.map((line, i) => (
          <div {...getLineProps({ line, key: i })}>
            {line.map((token, key) => (
              <span {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}
      </pre>
    )}
  </Highlight>
    )
  }
}
