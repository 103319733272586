import React, { Component } from 'react';
import PropTypes from 'prop-types';

import data from "lux/dsm-tokens";

import MainContent from 'components/shared/main-content';
import Pattern from 'components/shared/pattern';
import SwatchGroup from '../../shared/swatch-group';

export default class Colors extends Component {
  static propTypes = {
    colors: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hex: PropTypes.string.isRequired
    }))
  }

  setShortName(name) {
    return name.replace(/\s\bColors\b/g, '');
  }

  setId(name) {
    return name.replace(/\s+/g, '-').toLowerCase();
  }

  get navItems() {
    return data.list.colors.map((group) => (
      <li key={this.setId(group.name)}>
        <a href={`#` + this.setId(group.name)}>{this.setShortName(group.name)}</a>
      </li>
    ))
  }

  get colorSwatches() {
    return data.list.colors.map((group) => (
      <Pattern
        className="swatch-section"
        key={this.setId(group.name)}
        title={group.name}
        id={this.setId(group.name)}
      >
      {
        group.colors.map((color) =>
          <SwatchGroup
            key={this.setId(color.name)}
            name={color.name}
            hex={color.value}
            id={this.setId(color.name)}
          />
        )
      }
      </Pattern>
    ))
  }


  render() {

    return (
      <MainContent name="colors" title="Colors">
        <nav>
          <ul className="u-list-reset">
            {this.navItems}
            <li className="topLink"><a href="#">Back to top</a></li>
          </ul>
        </nav>
        {this.colorSwatches}
      </MainContent>
    );
  }
}
