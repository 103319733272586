import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MainContent from 'components/shared/main-content';

const PASSWORD = process.env.LUX_PASSWORD;

export default class Auth extends Component {
  static propTypes = {
    onPasswordSubmit: PropTypes.func
  };

  static defaultProps = {
    onPasswordSubmit: (isValid) => {}
  };

  constructor(...args) {
    super(...args);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    console.log('PASSWORD', PASSWORD)
  }

  state = {
    fields: {
      password: {
        value: '',
        touched: true,
        isValid: null
      }
    }
  };

  handleChange(event) {
    const fields = this.state.fields;

    fields.password.value = event.target.value;
    fields.password.touched = true;
    fields.password.isValid = null;

    this.setState({ fields });
  }

  handleSubmit(event) {
    event.preventDefault();

    const value = this.input.value;
    const fields = this.state.fields;

    if (value && PASSWORD) {
      const isValid = value === PASSWORD;
      this.props.onPasswordSubmit(isValid);

      fields.password.isValid = isValid;
      this.setState({ fields });
    }
  }

  render() {
    return (
      <div>
        <MainContent>
          <form className="lux-form" onSubmit={this.handleSubmit}>
            <div>
              <input
                type="password"
                name="lux-password"
                placeholder="Enter password..."
                onChange={this.handleChange}
                value={this.state.fields.password.value}
                ref={(input) => this.input = input}
              />
              {this.state.fields.password.isValid === false &&
                <span className="help-text is-invalid">
                  Sorry, that's not right.
                </span>
              }
            </div>

            <div>
              <button className="button button--primary">Submit</button>
            </div>
          </form>
        </MainContent>
      </div>
    );
  }
}
