import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import docs from 'docs';

export default class Documentation extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string
  };

  static defaultProps = {
    type: 'description'
  };

  render() {
    const nameDocs = docs[this.props.name];

    if (nameDocs) {
      const docSource = nameDocs[this.props.type];

      if (docSource) {
        return (
          <div className="guide-documentation">
            <Markdown source={docSource} />
          </div>
        );
      }
    }

    return null;
  }
}
