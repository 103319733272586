import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CodeToggle from 'components/shared/code-toggle';
import Documentation from 'components/shared/documentation';

export default class MainContent extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    name: PropTypes.string,
    showCodeToggle: PropTypes.bool,
    title: PropTypes.string
  };

  static defaultProps = {
    children: null,
    className: null,
    name: null,
    showCodeToggle: false,
    title: 'Hello'
  };

  constructor(...args) {
    super(...args);

    this.handleCodeToggleChange = this.handleCodeToggleChange.bind(this);
  }

  state = {
    showCode: true
  };

  handleCodeToggleChange(isOn) {
    this.setState({ showCode: isOn });
  }

  setHeader() {
    if (this.props.showCodeToggle && this.props.title) {
      return (
        <header>
          <h1 className="section-heading">{this.props.title}</h1>
          {this.props.showCodeToggle &&
            <CodeToggle isOn={this.state.showCode} onChange={this.handleCodeToggleChange} />
          }
        </header>
      )
    } else if (this.props.title) {
      return (
      <h1 className="section-heading">{this.props.title}</h1>
      )
    }
  }

  render() {
    const classes = classnames('guide-content', this.props.className, {
      'has-code': this.props.showCodeToggle, 'is-expanded': this.state.showCode && this.props.showCodeToggle
    });

    return (
      <main className={classes}>
        {this.setHeader()}

        {!!this.props.name &&
          <div>
            <Documentation name={this.props.name} type="description" />
          </div>
        }

        {this.props.children}

        {!!this.props.name &&
          <div>
            <Documentation name={this.props.name} type="footer" />
          </div>
        }
      </main>
    );
  }
}
