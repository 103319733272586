import React, { Component } from 'react';
export default class Main extends Component {
  render() {
    return (
      <main>
        <header>
          <h1>
            Lux: The Lumen Pattern Library
          </h1>
        </header>
        <p> ⚠️ This is currently a work in progress. <a href="https://docs.google.com/spreadsheets/d/1w5TJB7vaU7R3u_FwWRTwjGVXVhnAbH7ikm_9o1AX17k/edit?usp=sharing" target="_blank">View our roadmap.</a></p>
        <p>The React components, specifically, at this point are untested and should be considered proofs of concept. 😬</p>
      </main>
    );
  }
}
