import React, { Component } from 'react';

import ButtonToggle from 'lux/components/button-toggle';

import MainContent from 'components/shared/main-content';
import Pattern from 'components/shared/pattern';
import PatternGroup from 'components/shared/pattern-group';

import * as Code from './code';

export default class Buttons extends Component {
  render() {
    return (
      <MainContent name="buttons" title="Buttons" showCodeToggle>
        <Pattern title="Default Button" code={Code.Default} reactCode={Code.DefaultReact}>
          <div dangerouslySetInnerHTML={{ __html: Code.Default }}></div>
        </Pattern>

        <PatternGroup>
          <Pattern title="Disabled Button" inGroup code={Code.DefaultDisabled} reactCode={Code.DefaultDisabledReact}>
            <div dangerouslySetInnerHTML={{ __html: Code.DefaultDisabled }}></div>
          </Pattern>
        </PatternGroup>

        <PatternGroup title="Button Colors">
          <Pattern title="Primary Button" inGroup code={Code.Primary} reactCode={Code.PrimaryReact}>
            <div dangerouslySetInnerHTML={{ __html: Code.Primary }}></div>
          </Pattern>
        </PatternGroup>

        <PatternGroup title="Button Sizes">
          <Pattern inGroup code={Code.ButtonSizes} reactCode={Code.ButtonSizesReact}>
            <div dangerouslySetInnerHTML={{ __html: Code.ButtonSizes }}></div>
          </Pattern>
        </PatternGroup>

        <PatternGroup title="Button Groups">
          <Pattern title="Default Button Group" inGroup code={Code.ButtonGroup} reactCode={Code.ButtonGroupReact}>
            <div dangerouslySetInnerHTML={{ __html: Code.ButtonGroup }}></div>
          </Pattern>
          <Pattern title="Toggle Button" inGroup code={Code.ButtonToggle} reactCode={Code.ButtonToggleReact}>
            <ButtonToggle ariaLabel="Update this label for your feature." />
          </Pattern>
        </PatternGroup>
      </MainContent>
    );
  }
}
