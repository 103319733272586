import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import Logo from './logo';

export default class GuideHeader extends Component {
  render() {
    return (
      <header className="guide-header u-z-top">
        <Link to="/"><Logo /></Link>
      </header>
    );
  }
}
