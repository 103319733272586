import React, { Component } from 'react';

import MainContent from 'components/shared/main-content';
import Pattern from 'components/shared/pattern';

import * as Code from './code';

export default class Resets extends Component {

  render() {
    return (
      <MainContent name="u-resets" title="Resets" showCodeToggle>
        <Pattern title="List Reset" code={Code.ListReset}>
          <div dangerouslySetInnerHTML={{ __html: Code.ListReset }}></div>
        </Pattern>
        <Pattern title="Button Reset" code={Code.ButtonReset}>
          <div dangerouslySetInnerHTML={{ __html: Code.ButtonReset }}></div>
        </Pattern>
      </MainContent>
    );
  }
}
