import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class PatternGroup extends Component {
  static propTypes = {
    id: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string
  };

  static defaultProps = {
    id: null,
    children: null,
    title: null
  };

  render() {
    var titleString = this.props.title ? this.props.title.replace(/\s+/g, '-').toLowerCase() : null;
    var idAttribute = this.props.id || titleString;
    return (
      <section>
        {!!this.props.title &&
          <h2 id={idAttribute}>{this.props.title}</h2>
        }

        {this.props.children}
      </section>
    );
  }
}
