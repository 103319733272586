import React, { Component } from 'react';

import MainContent from 'components/shared/main-content';
import Pattern from 'components/shared/pattern';

import * as Code from './code';

export default class SROnly extends Component {

  render() {
    return (
      <MainContent name="u-sr-only" title="Screen Reader Only" showCodeToggle>
        <Pattern code={Code.Example}>
          <div dangerouslySetInnerHTML={{ __html: Code.Example }}></div>
        </Pattern>
      </MainContent>
    );
  }
}
