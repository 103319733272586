import React from 'react';

export default props => 
<svg 
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-hidden="true"
    className="icon icon-calendar"
>
  <g fill="none" fillRule="evenodd">
		<path fill="#FFF" d="M2 5h20v17H2z"/>
		<path d="M7 2v1.83h10.048V2h1.113v1.83H22V22H2V3.83h3.887V2H7zm13.81 7.037H3.19v11.945h17.62V9.037zM7 17v2H5v-2h2zm4 0v2H9v-2h2zm4 0v2h-2v-2h2zm-8-3v2H5v-2h2zm4 0v2H9v-2h2zm4 0v2h-2v-2h2zm4 0v2h-2v-2h2zM7 11v2H5v-2h2zm4 0v2H9v-2h2zm4 0v2h-2v-2h2zm4 0v2h-2v-2h2zM5.839 5.006H3.19V7.86h17.62V5.006H18.16v1.11h-1.113v-1.11H6.952v1.11H5.839v-1.11z" fill="#212B36"/>
	</g>
</svg>



