import React from 'react';
import classnames from 'classnames';

export default (props) => {
  const classes = classnames('icon icon-spinner', {
    'icon-spinner--primary': !!props.primary
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden="true"
      className={classes}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle cx="10" cy="10" r="8" stroke="#DFE3E8" />
        <g transform="translate(0.000000, 0.000000)" fill="currentColor" className="icon-spinner__arc">
          <path d="M10,18.5 C9.72385763,18.5 9.5,18.2761424 9.5,18 C9.5,17.7238576 9.72385763,17.5 10,17.5 C14.1421356,17.5 17.5,14.1421356 17.5,10 C17.5,9.72385763 17.7238576,9.5 18,9.5 C18.2761424,9.5 18.5,9.72385763 18.5,10 C18.5,14.6944204 14.6944204,18.5 10,18.5 Z" id="Oval"></path>
        </g>
      </g>
    </svg>
  );
};
