import React, { Component } from 'react';

import MainContent from 'components/shared/main-content';
import Pattern from 'components/shared/pattern';
import PatternGroup from 'components/shared/pattern-group';

import * as Code from './code';

export default class Forms extends Component {
  render() {
    return (
      <MainContent name="forms" title="Forms" showCodeToggle>
        <PatternGroup title="Text Input">
          <Pattern title="Default Text Input" inGroup code={Code.DefaultTextInput}>
            <div dangerouslySetInnerHTML={{ __html: Code.DefaultTextInput }}></div>
          </Pattern>
          <Pattern title="Disabled Text Input" inGroup code={Code.DisabledTextInput}>
            <div dangerouslySetInnerHTML={{ __html: Code.DisabledTextInput }}></div>
          </Pattern>
        </PatternGroup>
        <PatternGroup title="Validation">
          <Pattern title="Error" inGroup code={Code.InvalidTextInput}>
            <div dangerouslySetInnerHTML={{ __html: Code.InvalidTextInput }}></div>
          </Pattern>
        </PatternGroup>
        <PatternGroup title="Text Input Variations">
          <Pattern title="Full-width Input" inGroup code={Code.FWTextInput}>
            <div dangerouslySetInnerHTML={{ __html: Code.FWTextInput }}></div>
          </Pattern>
          <Pattern title="Icon First" inGroup code={Code.IconPrefixInput}>
            <div dangerouslySetInnerHTML={{ __html: Code.IconPrefixInput }}></div>
          </Pattern>
          <Pattern title="Icon Last" inGroup code={Code.IconSuffixInput}>
            <div dangerouslySetInnerHTML={{ __html: Code.IconSuffixInput }}></div>
          </Pattern>
        </PatternGroup>
        <PatternGroup title="Select/Dropdown">
          <Pattern title="Default Select" inGroup code={Code.DefaultSelect}>
            <div dangerouslySetInnerHTML={{ __html: Code.DefaultSelect }}></div>
          </Pattern>
          <Pattern title="Disabled Select" inGroup code={Code.DisabledSelect}>
            <div dangerouslySetInnerHTML={{ __html: Code.DisabledSelect }}></div>
          </Pattern>
        </PatternGroup>
      </MainContent>
    );
  }
}
