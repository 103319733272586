import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ButtonToggle extends Component {
  static propTypes = {

    /**
     * Sets the the value for the aria-label.
     * @type {String}
     */
    ariaLabel: PropTypes.string,

    /**
     * Sets first of two toggle options.
     * @type {String}
     */
    optionOne: PropTypes.string,

    /**
     * Sets second of two toggle options.
     * @type {String}
     */
    optionTwo: PropTypes.string,
  };

  static defaultProps = {
    ariaLabel: null,
    optionOne: 'On',
    optionTwo: 'Off'
  };

  constructor(...args) {
    super(...args);
    this.state = {checked: false};
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.setState(prevState => ({
      checked: !prevState.checked
    }));
  }

  render() {

    return (
      <button
        type='button'
        role='switch'
        aria-checked={this.state.checked}
        aria-label={this.props.ariaLabel}
        className='u-button-reset button-group button-toggle'
        onClick={this.handleToggle}
      >
        <span className="button">{this.props.optionOne}</span>
        <span className="button">{this.props.optionTwo}</span>
      </button>
    );
  }
}
