export default [
  {
    id: 'core',
    text: 'Core',
    items: [
      { id: 'colors', text: 'Colors', path: '/colors' },
      { id: 'icons', text: 'Icons', path: '/icons' },
    ]
  },
  {
    id: 'components',
    text: 'Components',
    items: [
      { id: 'buttons', text: 'Buttons', path: '/buttons' },
      { id: 'forms', text: 'Forms', path: '/forms' },
    ]
  },
  {
    id: 'utilities',
    text: 'Utilities',
    items: [
      { id: 'allcaps', text: 'All Caps', path: '/allcaps' },
      { id: 'display', text: 'Display', path: '/display' },
      { id: 'pointer', text: 'Pointer', path: '/pointer' },
      { id: 'resets', text: 'Resets', path: '/resets' },
      { id: 'spacing', text: 'Spacing', path: '/spacing' },
      { id: 'sr-only', text: 'SR Only', path: '/sr-only' },
      { id: 'zindex', text: 'Z Index', path: '/z-index' },
    ]
  },
  {
    id: 'resources',
    text: 'Resources',
    items: [
      { id: 'changelog', text: 'Changelog', path: '/changelog' },
    ]
  }
];
