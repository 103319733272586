import React, { Component } from 'react';

import MainContent from 'components/shared/main-content';
import Pattern from 'components/shared/pattern';

import * as Code from './code';
import * as Icons from 'lux/components/icons';

export default class IconsView extends Component {
  render() {
    return (
      <MainContent title="Icons" showCodeToggle>
        <Pattern title="Calendar" code={Code.Calendar} reactCode={Code.CalendarReact}>
          <Icons.CalendarIcon />
        </Pattern>
        <Pattern title="Animated" code={Code.Spinner} reactCode={Code.SpinnerReact}>
          <Icons.SpinnerIcon />
          <Icons.SpinnerIcon primary />
        </Pattern>
      </MainContent>
    );
  }
}
