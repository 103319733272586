import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CodeToggle extends Component {
  static propTypes = {
    isOn: PropTypes.bool,
    onChange: PropTypes.func
  };

  static defaultProps = {
    isOn: false,
    onChange: (isOn) => { }
  };

  constructor(...args) {
    super(...args);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, isOn) {
    event.preventDefault();

    if (this.props.isOn !== isOn) {
      this.props.onChange(isOn);
    }
  }

  render() {
    return (
      <div className="guide-code-toggle u-z-top">
        <p className="guide-code-toggle__label">View Code</p>
        <div className="guide-code__buttons">
          <button
            type="button"
            onClick={event => this.handleChange(event, true)}
            className={"guide-code__label" + (this.props.isOn ? " is-selected":"")}
          >On</button>
          <button
            type="button"
            onClick={event => this.handleChange(event, false)}
            className={"guide-code__label" + (this.props.isOn ? "":" is-selected")}
          >Off</button>
        </div>
      </div>
    );
  }
}
