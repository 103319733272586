import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from 'components/home';

import {
  Colors,
  Icons,

  Buttons,
  Forms,

  AllCaps,
  Display,
  Resets,
  Pointer,
  Spacing,
  SROnly,
  ZIndex,

  Changelog,

  NotFound
} from 'components/views';

export default props =>
<Switch>
  <Route exact path="/" component={Home} />
  <Route path="/colors" component={Colors} />
  <Route path="/icons" component={Icons} />
  <Route path="/buttons" component={Buttons} />
  <Route path="/forms" component={Forms} />
  <Route path="/allcaps" component={AllCaps} />
  <Route path="/display" component={Display} />
  <Route path="/pointer" component={Pointer} />
  <Route path="/resets" component={Resets} />
  <Route path="/spacing" component={Spacing} />
  <Route path="/sr-only" component={SROnly} />
  <Route path="/z-index" component={ZIndex} />
  <Route path="/changelog" component={Changelog} />
  <Route component={NotFound} />
</Switch>;
