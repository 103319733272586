import React, { Component } from 'react';

import Routes from 'lib/routes';

export default class GuideMain extends Component {
  render() {
    return (
      <Routes />
    );
  }
}
